import React, { useState } from 'react'
import { Link } from '@remix-run/react'

import EventDate from '../EventDate/EventDate'
import EventArtists from './EventArtists'
import EventFooterButtons from './EventFooterButtons'
import EventCardMenu from './EventCardMenu/EventCardMenu'

import * as styles from './EventCard.styles'
import { timeFormat } from '../../utilities/dateTimeUtils'
import { AcceptedIndicator } from './AcceptedIndicator'
import { isUserEventParticipant } from '../../utilities/isUserEventParticipant'
import { useCurrentUser } from '../../graphql/hooks'
import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'

type EventCardProps = {
  event: EventDetailsFragment
  displayMenu?: boolean
  displayFooter?: boolean
}

const EventCard: React.FC<EventCardProps> = ({
  event,
  displayMenu = true,
  displayFooter = true,
}) => {
  const [didPictureError, setDidPictureError] = useState(false)
  const { slug, picture, startTime, venue, genres, artists } = event
  const datetime = new Date(startTime)

  const { user } = useCurrentUser({ fetchPolicy: 'cache-only' })
  const userIsParticipant = isUserEventParticipant(user, event)

  return (
    <div css={[styles.eventCard, !event.venueAccepted && styles.notAccepted]}>
      <div css={styles.cardHeader}>
        <div css={styles.headerInfoWrapper}>
          <div css={styles.topLeftContainer}>
            {datetime && <EventDate eventSlug={slug} date={datetime} />}
          </div>
          {artists && (
            <div css={styles.artists}>
              <EventArtists
                event={event}
                userIsParticipant={userIsParticipant}
              />
            </div>
          )}
        </div>

        {displayMenu && (
          <div css={styles.menuButtonWrapper}>
            <EventCardMenu event={event} />
          </div>
        )}
      </div>
      {/* Make genre name and other vars non-optional? */}
      {genres && (
        <div css={styles.genresContainer}>
          {genres.map(
            (genre) =>
              genre &&
              genre.name && (
                <Link
                  to={`/genres/${genre.name.toLowerCase()}`}
                  key={genre.name}
                >
                  {/* Extract to own component  */}
                  {genre.name}
                </Link>
              ),
          )}
        </div>
      )}

      <span
        css={styles.timeAndVenueContainer}
        style={!picture && !displayFooter ? { marginBottom: 20 } : {}}
      >
        {datetime && (
          <span css={styles.time}>
            <i css={styles.icon} className="fas fa-clock" />
            {timeFormat.format(datetime)}
          </span>
        )}
        {venue ? (
          <Link
            css={styles.venue}
            to={`/venues/${venue.slug}`}
            prefetch="viewport"
          >
            <span>
              <i css={styles.icon} className="fas fa-map-marker-alt" />
              {venue.name}
            </span>
            {userIsParticipant && (
              <AcceptedIndicator accepted={event.venueAccepted} />
            )}
          </Link>
        ) : (
          <small css={styles.venue}>Venue email sent</small>
        )}
      </span>

      {picture && !didPictureError && (
        <div css={styles.imageContainer}>
          <div>
            <Link css={styles.imageLink} to={`/event/${slug}`}>
              <img
                css={styles.image}
                src={picture}
                alt=""
                onError={() => {
                  setDidPictureError(true)
                }}
              />
            </Link>
          </div>
        </div>
      )}

      {displayFooter && <EventFooterButtons event={event} />}
    </div>
  )
}

export default EventCard
