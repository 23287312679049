export const footer: Style = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginBottom: 0,
})

export const footerButton: Style = ({ size, fontColor }) => ({
  display: 'flex',
  gap: size.XXS,
  flex: 1,
  justifyContent: 'center',
  alignItems: 'baseline',
  color: fontColor.plain.secondary,
  paddingTop: size.S,
  paddingBottom: size.S,
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontSize: size.S,
  letterSpacing: '1px',

  ':hover': {
    '&>span, i, &': {
      color: fontColor.plain.primary,
    },
  },
  ':visited': {
    color: fontColor.plain.secondary,
  },
})

export const icon: Style = () => ({
  margin: 0,
  padding: 0,
  marginRight: '0.3em',
  lineHeight: 1,
})
