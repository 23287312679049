import { Link } from '@remix-run/react'

import * as styles from './EventArtists.styles'
import { PromoterLink } from './PromoterLink'
import { AcceptedIndicator } from './AcceptedIndicator'
import { Spacer } from '../Spacer'
import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'

interface EventArtstsProps {
  event: EventDetailsFragment
  userIsParticipant?: boolean
}

const EventArtists: React.FC<EventArtstsProps> = ({
  event,
  userIsParticipant,
}) => {
  const { artists = [], artistIdsAccepted } = event

  return (
    <section css={styles.container}>
      {artists.length < 1 && <span>Artist emails sent.</span>}
      {artists.map((artist, index) => {
        const hasAccepted = artistIdsAccepted?.includes(artist?.id)
        return (
          <Link
            css={[
              styles.linkWrapper(index),
              !hasAccepted && styles.notAccepted,
            ]}
            key={artist!.name!}
            to={`/artists/${artist!.slug}`}
            prefetch="viewport"
          >
            <h3 css={styles.artistName}>
              {artist!.name}{' '}
              {userIsParticipant && (
                <AcceptedIndicator accepted={hasAccepted} />
              )}
            </h3>
          </Link>
        )
      })}
      <Spacer y="XS" />
      <PromoterLink event={event} userIsParticipant={userIsParticipant} />
    </section>
  )
}

export default EventArtists
