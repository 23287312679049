import { css } from '@emotion/react'
import { mTheme } from '../../../style/themes'

export const menuButton: Style = css({
  color: mTheme.fontColor.plain.tertiary,
  background: 'transparent',
  cursor: 'pointer',

  ':hover': {
    color: mTheme.fontColor.plain.secondary,
  },
})

export const menuButtonWrapper = css({
  position: 'relative',
  top: '-10px',
  right: '-10px',
})

export const menuItemStyle: Style = css({
  padding: mTheme.size.S,
  cursor: 'pointer',
  display: 'flex',
  gap: mTheme.size.XS,
  color: mTheme.fontColor.plain.primary,
  ':visited': {
    color: mTheme.fontColor.plain.primary,
  },
})
