/* eslint-disable */
import * as Types from '../../../graphql/types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type GetEventContextMenuQueryVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input']
}>

export type GetEventContextMenuQuery = {
  __typename?: 'Query'
  getEventContextMenu: {
    __typename?: 'EventContextMenuResult'
    ok?: Array<Types.EventContextMenuItem> | null
  }
}

export const GetEventContextMenuDocument = gql`
  query getEventContextMenu($eventId: ID!) {
    getEventContextMenu(id: $eventId) {
      ok
    }
  }
`

/**
 * __useGetEventContextMenuQuery__
 *
 * To run a query within a React component, call `useGetEventContextMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventContextMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventContextMenuQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventContextMenuQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventContextMenuQuery,
    GetEventContextMenuQueryVariables
  > &
    (
      | { variables: GetEventContextMenuQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEventContextMenuQuery,
    GetEventContextMenuQueryVariables
  >(GetEventContextMenuDocument, options)
}
export function useGetEventContextMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventContextMenuQuery,
    GetEventContextMenuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEventContextMenuQuery,
    GetEventContextMenuQueryVariables
  >(GetEventContextMenuDocument, options)
}
export function useGetEventContextMenuSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEventContextMenuQuery,
    GetEventContextMenuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetEventContextMenuQuery,
    GetEventContextMenuQueryVariables
  >(GetEventContextMenuDocument, options)
}
export type GetEventContextMenuQueryHookResult = ReturnType<
  typeof useGetEventContextMenuQuery
>
export type GetEventContextMenuLazyQueryHookResult = ReturnType<
  typeof useGetEventContextMenuLazyQuery
>
export type GetEventContextMenuSuspenseQueryHookResult = ReturnType<
  typeof useGetEventContextMenuSuspenseQuery
>
export type GetEventContextMenuQueryResult = Apollo.QueryResult<
  GetEventContextMenuQuery,
  GetEventContextMenuQueryVariables
>
