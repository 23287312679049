import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

let { size, color, fontColor, radius, backgroundColor, breakpoint, pageWidth } =
  mTheme

export const eventCard: Style = css({
  display: 'grid',
  gridColumn: 1,
  breakInside: 'avoid',

  alignItems: 'stretch',
  textAlign: 'left',
  padding: '0',
  width: '100%',
  height: 'fit-content',
  borderRadius: radius.primary,
  backgroundColor: backgroundColor.card,
  userSelect: 'none',
  boxShadow: `16px 16px 10px -16px rgba(0,0,0,0.1)`,
  overflow: 'hidden',

  [breakpoint.large]: {
    minWidth: pageWidth.S,
    maxWidth: pageWidth.M,
  },
})

export const artists: Style = css({
  margin: `-2px 0 0 ${size.XS}`,
  height: '100%',
  display: 'flex',
  alignSelf: 'center',
  color: fontColor.plain.primary,
  letterSpacing: 1.25,
})

export const button: Style = css({
  background: 'transparent',
  margin: 0,
})

export const cardHeader: Style = css({
  display: 'flex',
  marginTop: 0,
  padding: `${size.M} ${size.M} ${size.S} ${size.M} `,
  justifyContent: 'space-between',
})

export const headerInfoWrapper: Style = css({
  display: 'flex',
  flexDirection: 'row',
})

export const menuButtonWrapper: Style = css({
  top: '0px',
  right: '0px',
  marginTop: '-10px',
  marginRight: '-15px',
})

export const timeAndVenueContainer: Style = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  margin: `0 ${size.S} ${size.S} ${size.S}`,
  border: `2px solid ${backgroundColor.innerCard}`,
  borderRadius: radius.primary,
  color: fontColor.plain.primary,
})

export const time: Style = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: size.XS,
  marginRight: size.XS,
  letterSpacing: 1.2,
})

export const icon: Style = css({
  marginRight: '0.3em',
  marginTop: '-0.1em',
})

export const timeAndVenueItem: Style = css({
  padding: size.XS,
  margin: 0,
})

export const venue: Style = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: size.XXS,
  marginLeft: 0,
  marginRight: 0,
  paddingLeft: size.XS,
  paddingTop: size.S,
  paddingBottom: size.S,
  backgroundColor: backgroundColor.innerCard,
  borderRadius: `0 ${radius.primary} ${radius.primary} 0`,
  width: '100%',
  color: color.primary,
  letterSpacing: 1.1,
  '&, &:visited': {
    color: color.primary,
  },
  '&:hover': {
    color: fontColor.link.hover,
  },
})

export const notAccepted: Style = css({
  opacity: 0.7,
  color: mTheme.fontColor.plain.secondary,
})

export const image = css({
  maxHeight: '1000px',
  minHeight: '150px',
  width: '100%',
  pointerEvents: 'none',
  userSelect: 'none',
  objectFit: 'cover',
  display: 'flex',
  flex: 1,
})

export const imageLink: Style = {
  width: '100%',
  display: 'block',
}

export const imageContainer: Style = css({
  // overflow: 'hidden',
})

export const genresContainer: Style = css({
  display: 'flex',
  gap: size.XS,
  margin: size.S,
  marginBottom: size.XS,
  i: {
    marginLeft: '-0.11rem',
    marginRight: size.S,
    color: fontColor.plain.secondary,
  },
  a: {
    padding: size.XS,
    background: backgroundColor.innerCard,
    color: fontColor.plain.tertiary,
    borderRadius: radius.primary,
    cursor: 'pointer',
  },
})

export const topLeftContainer: Style = {
  display: 'flex',
  flexDirection: 'column',
  gap: mTheme.size.M,
}
