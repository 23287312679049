import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

const { size, fontSize, fontColor } = mTheme

export const artistName: Style = css({
  display: 'flex',
  alignItems: 'center',
  gap: size.XXS,
  margin: '0',
  fontWeight: 'bold',
  fontSize: fontSize.XL,
  lineHeight: fontSize.XL,
  wordBreak: 'break-word',
})

export const container: Style = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: `0 0 0 ${size.S} !important`,
  justifyContent: 'center',
})

export const linkWrapper: StyleWithOptions<number> = (index) =>
  css({
    '&, &:hover, &:visited': {
      color: fontColor.plain.primary,
    },
    margin: 0,
    marginTop: index === 0 ? 0 : size.S,
  })

export const notAccepted: Style = css({
  opacity: 0.5,
})

export const singleArtistContainer: Style = css({
  display: 'flex',
  alignItems: 'center',
})
